<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              
              <v-data-table
                :headers="headers"
                :items="brands"
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5; font-size: 10em"
                
              >
                <!-- 
                      :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
                  <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>brand ID : </b> {{ item.id }} <br />
                    <b>Name : </b> {{ item.Brand_name }}<br />
                    <b>Loction : </b> {{ item.brand_location }}<br />
                    <b>Quantity : </b> {{ item.spacification }}<br />
                  </td>
                </template> -->
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >Barcode Details
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn text @click="Print(item)" color="primary"  style="
                      padding-left: 0 !important;
                      padding-right: 0 !important;
                    "
                    class="px-0">
                    print
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered color="black">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    snackbar: "",

    headers: [
     
      { text: "Specification ID", value: "specification_id", sortable: false, },
      { text: "Barcode ", value: "barcode", sortable: false },
      { text: "Purchase Price ", value: "purchase_price", sortable: false },
      { text: "Selling Price", value: "selling_price", sortable: false },
      { text: "Quantity", value: "credit", sortable: false },
      { text: "Print", value: "actions", sortable: false },
    ],

    brands: [],
    contact: {},
    editedIndex: -1,

    text: "",
    snackbar: false,
  }),

  methods: {
    initialize() {
      axios
        .get(`productdetails/get_barcode_information/${this.$route.query.id}/`)
        .then((response) => {
          this.brands = response.data.data;
          // console.log(this.brands);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>




<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>


